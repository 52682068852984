// extracted by mini-css-extract-plugin
export var article = "resume-article--tabkp";
export var articleList = "resume-article-list--4W2wV";
export var boxLink = "resume-box-link--FBGAp";
export var category = "resume-category--yEnmK";
export var company = "resume-company--cpv9T";
export var contact = "resume-contact--lZu8Z";
export var headingH3 = "resume-heading--h3--d0dLC";
export var links = "resume-links--ALgdn";
export var listCategory = "resume-list-category--oKEXy";
export var listCircles = "resume-list-circles--UXwk2";
export var listInline = "resume-list-inline--iRZ6O";
export var listPlain = "resume-list-plain--9ghfD";
export var listSmColumn = "resume-list-sm-column--6tGTi";
export var listTag = "resume-list-tag--J3y0C";
export var logo = "resume-logo--LfW1k";
export var logos = "resume-logos--kxxgB";
export var optiLogo = "resume-optiLogo--PGGmR";
export var profileHeader = "resume-profileHeader--s9Cp7";
export var profileImage = "resume-profileImage--yfIfT";
export var sectionHeadline = "resume-section-headline--QEdhI";
export var skills = "resume-skills--dXNO6";
export var spacingVBLg = "resume-spacing-v-b-lg--24LSZ";
export var spacingVBMd = "resume-spacing-v-b-md--0ZMgl";
export var spacingVBSm = "resume-spacing-v-b-sm--LVy8p";
export var spacingVBZ = "resume-spacing-v-b-z--o2GuS";
export var spacingVTLg = "resume-spacing-v-t-lg--t9dP5";
export var spacingVTMd = "resume-spacing-v-t-md--w1f4F";
export var spacingVTSm = "resume-spacing-v-t-sm--wMsWI";
export var spacingVTZ = "resume-spacing-v-t-z--7KxBa";
export var textLink = "resume-textLink--yWr9q";
export var uFlex = "resume-u-flex--LLDkO";
export var uLg1of4 = "resume-u-lg-1of4--CUcgF";
export var uLg3of4 = "resume-u-lg-3of4--9L+IP";
export var wrapper = "resume-wrapper--4v57V";